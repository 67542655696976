import { default as _91slug_93JINaVWdZmzMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93BWqPpVxapFMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93y4xT2TVTjKMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91paxid_931GB1XLR6v5Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue?macro=true";
import { default as _91id_93QnzmsYH3XwMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93ygHRvvTDP6Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_936paXS8WIaJMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_93RKUVeYNI0EMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_93V57dpv09nNMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_93niNSaJjcdjMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue?macro=true";
import { default as _91slug_93WRqLPcA7p7Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91id_93mrM9PlgxGMMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93ctNEkcbkSMMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue?macro=true";
import { default as _91slug_9378D6IOTZRfMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91slug_93GZQMUsw4a1Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue?macro=true";
import { default as _91ocr_93YRbgjNYx4xMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93gd7fy8JWSWMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93G96oTKczHXMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91step_93BsmAi6ND1jMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_93KHYtDpis8LMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue?macro=true";
import { default as _91jwt_93IVUrp1kTZyMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93q58gec6xZ0Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as indexBdzq0SZilDMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as callbacknJlNuC19IVMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue?macro=true";
import { default as blackweeklnfHvq24uaMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue?macro=true";
import { default as _91slug_93qDaNfT5UbmMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue?macro=true";
import { default as indexLCNQA8hYo1Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue?macro=true";
import { default as byebyeiehNxW7X7DgiMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as indexFSy4tMUTJSMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue?macro=true";
import { default as lastminutewHOvq6PGljMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_93XAzBF8HdoiMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue?macro=true";
import { default as indexznShzJY51gMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue?macro=true";
import { default as catalogue055jjImWY3Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue?macro=true";
import { default as complaintaQLbBqfjipMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as dinbokning_45aktuellnxjFLpzxUtMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93cKWNJ9W0K7Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue?macro=true";
import { default as indexLTsdxUIbzSMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue?macro=true";
import { default as faqNux08mwovyMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as _91id_93asCX9vGp7UMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexgmM6jNWHgGMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as _91name_93IDS2driFeCMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexqezOW1yxkzMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as indexIbG8BHdKCZMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue?macro=true";
import { default as grouptravelI9cX9xHtmuMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue?macro=true";
import { default as indexhnIJx811bhMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue?macro=true";
import { default as lastminuteQLPrk27WIkMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue?macro=true";
import { default as mapeSKoAG5OlzMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue?macro=true";
import { default as dinbokning9Sgg1GOrdbMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletter9N0U6eV8aRMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlinenUq3GP6QK3Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as failedS6wZx6dWKiMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as index7gP0Gnn9TmMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as searchyij5T6RCLSMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexiMu9H4WP9jMeta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45donepnWBbq5Rr5Meta } from "/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reiseleder/:id()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/reiseplan",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/cruiseskip",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "satisfactionstatus-triptypeid",
    path: "/tilfredshet-status/:triptypeid()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinreise",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info-lectures",
    path: "/info/forelesninger",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "info-connections",
    path: "/info/anslutning",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-slug",
    path: "/destination/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "competition",
    path: "/konkurranse",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "book-tripid",
    path: "/bestill/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93q58gec6xZ0Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue")
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue")
  },
  {
    name: "blog-slug",
    path: "/blogg/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blogg",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue")
  },
  {
    name: "book-tripid-step",
    path: "/bestill/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: indexFSy4tMUTJSMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue")
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/restplasser",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue")
  },
  {
    name: "campaign-name",
    path: "/kampanje/:name()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign",
    path: "/kampanje",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue")
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: catalogue055jjImWY3Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue")
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_93GZQMUsw4a1Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "competition-slug",
    path: "/konkurranse/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamasjon",
    meta: complaintaQLbBqfjipMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-slug-tab",
    path: "/destination/:slug()/:tab()",
    meta: _91tab_93ctNEkcbkSMMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "event-slug",
    path: "/arrangement/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue")
  },
  {
    name: "event",
    path: "/arrangement",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/gavekort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/gavekort",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hoteller/:name()",
    meta: _91name_93IDS2driFeCMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hoteller",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93WRqLPcA7p7Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info-connections-tab",
    path: "/info/anslutning/:tab()",
    meta: _91tab_93V57dpv09nNMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-grouptravel",
    path: "/info/gruppereiser",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue")
  },
  {
    name: "info-lectures-tab",
    path: "/info/forelesninger/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "lastminute",
    path: "/restplasser-tilbud",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue")
  },
  {
    name: "map",
    path: "/kart",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinreise/:tab()",
    meta: _91tab_936paXS8WIaJMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93ygHRvvTDP6Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/evaluere-reisen",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "satisfactionstatus-triptypeid-paxid",
    path: "/tilfredshet-status/:triptypeid()/:paxid()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/cruiseskip/:name()",
    meta: _91name_93y4xT2TVTjKMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/reiseplan/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reiseleder/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reiseleder",
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45donepnWBbq5Rr5Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-02-13-1139/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]